import React from "react"
import styled, { withTheme } from "styled-components"
import Seo from "../components/molecules/Seo"
import Container from "../components/atoms/Container"
import WorkCard from "../components/molecules/WorkCard"
import ContentHolder from "../components/organisms/ContentHolder"
import Splash from "../components/organisms/Splash"
import { workList } from "../data/workList"
import ArrowText from "../components/atoms/ArrowText"
import thumb from "../../static/images/cw_home.png"

const Holder = styled.ul`
  list-style: none;
  padding-left: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  margin-bottom: 6rem;
  @media (${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

function WorkList({ theme }) {
  return (
    <>
      <Seo
        title="Work | Craig Walker"
        thumb={thumb}
        description="Craig Walker designs and researches for the world’s leading organisations. These are some projects we’re proud of."
      />
      <Splash
        bg={theme.colours.orange}
        text={
          <>
            <b>We design</b> <br />
            with craft and expertise.
          </>
        }
      >
        <ArrowText>
          <p>
            Some projects we’re <br />
            proud of
          </p>
        </ArrowText>
      </Splash>
      <ContentHolder>
        <Container>
          <Holder>
            {workList.map((work) => (
              <WorkCard key={work.slug} work={work} />
            ))}
          </Holder>
        </Container>
      </ContentHolder>
    </>
  )
}

export default withTheme(WorkList)
